import {Component, HostListener, Inject, LOCALE_ID, Renderer2} from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import {AuthenticationService} from "./shared/services/authentication.service";
import {SearchService} from "./shared/services/search.service";
import {UserLocalService} from "./shared/services/userLocal.service";

@Component({
  selector: 'canvas-next-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private auth: AuthenticationService,
              private userLocalService: UserLocalService,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              protected searchService: SearchService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    // this.route.queryParamMap.subscribe(queryParamMap => {
    //   if (queryParamMap.has('layout')) {
    //     this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
    //   }
    //
    //   if (queryParamMap.has('style')) {
    //     this.configService.updateConfig({
    //       style: {
    //         colorScheme: queryParamMap.get('style') as ColorSchemeName
    //       }
    //     });
    //   }
    //
    //   if (queryParamMap.has('primaryColor')) {
    //     const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];
    //
    //     if (color) {
    //       this.configService.updateConfig({
    //         style: {
    //           colors: {
    //             primary: color
    //           }
    //         }
    //       });
    //     }
    //   }
    //
    //   if (queryParamMap.has('rtl')) {
    //     this.configService.updateConfig({
    //       direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
    //     });
    //   }
    // });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Hlavní',
        availableTo: '*',
        children: [
          {
            type: 'link',
            label: 'Nástěnka',
            route: '/',
            icon: 'mat:insights',
            availableTo: '*',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Díla',
            route: '/dila',
            icon: 'mat:burst_mode',
            availableTo: ['Admin', 'User', 'Guest'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Klienti',
            route: '/klienti',
            icon: 'mat:work',
            availableTo: ['Admin', 'User'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Autoři',
            route: '/autori',
            icon: 'mat:perm_contact_calendar',
            availableTo: ['Admin', 'User', 'Guest'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Spolupracovníci',
            route: '/spolupracovnici',
            icon: 'mat:groups',
            availableTo: ['Admin', 'User'],
            routerLinkActiveOptions: { exact: false }
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Aplikace',
        availableTo: ['Admin', 'Translator', 'Photographer', 'Limiter'],
        children: [
          {
            type: 'dropdown',
            label: 'Limitace',
            availableTo: ['Admin', 'Limiter'],
            icon: 'mat:checklist',
            children: [
              {
                type: 'link',
                label: 'Limitace',
                route: '/limitace',
                icon: 'mat:checklist',
                availableTo: ['Admin', 'Limiter'],
                routerLinkActiveOptions: {exact: true}
              },
              {
                type: 'link',
                label: 'Souhrn limitace',
                route: '/limitace/souhrn',
                icon: 'mat:calendar_today',
                availableTo: ['Admin', 'Limiter'],
                routerLinkActiveOptions: {exact: false}
              },
              {
                type: 'link',
                label: 'Telefonisti',
                route: '/limitace/telefonisti',
                icon: 'mat:phone',
                availableTo: ['Admin', 'Limiter'],
                routerLinkActiveOptions: {exact: false}
              },
            ]
          },
          {
            type: 'link',
            label: 'Překlady obrazů',
            route: '/aplikace/preklady-obrazu',
            icon: 'mat:translate',
            availableTo: ['Admin', 'Translator'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Správa fotek',
            route: '/aplikace/sprava-fotek',
            icon: 'mat:add_a_photo',
            availableTo: ['Admin', 'Photographer'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Import XML',
            route: '/aplikace/import-xml',
            icon: 'mat:import_export',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Publikování',
            route: '/aplikace/publikovani',
            icon: 'mat:publish',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false }
          }


        ]
      },
      {
        type: 'subheading',
        label: 'Nastavení',
        availableTo: ['Admin'],
        children: [
          {
            type: 'link',
            label: 'Uživatelé systému',
            route: '/nastaveni/uzivatele-systemu',
            icon: 'mat:manage_accounts',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Termíny aukcí',
            route: '/nastaveni/terminy-aukci',
            icon: 'mat:calendar_today',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Šablony smluv',
            route: '/nastaveni/sablony-smluv',
            icon: 'mat:import_contacts',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Štítky',
            route: '/nastaveni/stitky',
            icon: 'mat:sell',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false }
          }
        ]
      },
    ];
    // this.auth.getProfile();
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key === 'k' && event.metaKey || event.key === 'k' && event.ctrlKey) {
      this.searchService.openSearch(); // Do search
    }
  }

}
